<!-- eslint-disable -->
<template>
  <div id="shipments">
    <good-table-advance-search
      :rows="rows"
      :columns="columns"
      :columnState="columnState"
    />
  </div>
</template>

<script>
import GoodTableAdvanceSearch from "@/views/used-tables/GoodTableAdvanceSearch.vue";
import { VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    GoodTableAdvanceSearch,
  },
  provide: {
    link: "shipments",
    filterName: "apps-todo-shipments-filter",
    tagName: "apps-todo-shipments-tag",
    appName: "apps-todo-shipments",
    emailFilterName: "apps-email-shipments-folder",
    labelName: "apps-email-shipments-label",
    appEmailName: "apps-email-shipments",
  },
  data() {
    return {
      columns: [
        {
          label: "Name",
          field: "fullName",
          hidden: false,
        },
        {
          label: "Phone",
          field: "phone",
          hidden: false,
        },
        {
          label: "Address",
          field: "address",
          hidden: false,
        },
        {
          label: "Expected delivery date",
          field: "expectedDeliveryDate",
          hidden: false,
        },
        {
          label: "Preferred delivery date",
          field: "preferredDeliveryDate",
          hidden: false,
        },
        {
          label: "Status",
          field: "status",
          hidden: false,
        },
        {
          label: "Email",
          field: "email",
          hidden: false,
        },
        {
          label: "Country",
          field: "country",
          hidden: false,
        },
        {
          label: "City",
          field: "city",
          hidden: false,
        },
        {
          label: "Shipping carrier",
          field: "shippingCarrier",
          hidden: false,
        },
        {
          label: "Shipping country",
          field: "shipingCountry",
          hidden: true,
        },
        {
          label: "Return",
          field: "return",
          hidden: true,
        },
        {
          label: "Replacement",
          field: "replacement",
          hidden: true,
        },
        {
          label: "Shipping collected",
          field: "sgippingCollected",
          hidden: true,
        },
      ],
      rows: [],
      updatedColsShipments: [],
    };
  },
  created() {
    // eslint-disable-next-line arrow-parens
    // this.$http.get("/good-table/advanced-search").then((res) => {
    //   this.rows = res.data;
    // });
  },
  mounted() {
    // eslint-disable-next-line operator-linebreak
    this.columns =
      JSON.parse(localStorage.getItem("updatedColsShipments")) || this.columns;

    // eslint-disable-next-line operator-linebreak
    this.rows = JSON.parse(localStorage.getItem("shippmentsRows")) || this.rows;

    this.$router.push("/shipments").catch(() => {});
  },
  methods: {
    columnState() {
      this.updatedColsShipments = [];
      this.columns.forEach((column, index) => {
        this.updatedColsShipments.push(this.columns[index]);
        localStorage.setItem(
          "updatedColsShipments",
          // eslint-disable-next-line comma-dangle
          JSON.stringify(this.updatedColsShipments)
        );
      });
    },
  },
};
</script>

<style>
#shipments .right-header-col a {
  padding-right: 0;
}
</style>
